<template>
  <div id="addExchangeActionList">
    <!-- 表单 -->
    <div class="tip">
      <i class="el-icon-warning-outline"></i>
      提取码是学员提取课程的唯一凭证，每重置提取码，之前分享的提取码会失效，未提取的无法提取，已提取的不影响
    </div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
      label-position="left"
    >
      <el-form-item label="提取码">
        <el-input
          v-model="ruleForm.exchange_code"
          class="w520"
          disabled
        ></el-input>
        <el-button type="text" class="w100 ml10" @click="resetTQM">
          重置提取码
        </el-button>
      </el-form-item>
      <el-form-item label="活动名称" prop="name">
        <el-input
          v-model="ruleForm.name"
          class="w618"
          placeholder="请输入提取码名称"
          maxlength="12"
          :show-word-limit="true"
        ></el-input>
      </el-form-item>
      <el-form-item prop="stock">
        <span slot="label">
          可提取次数
          <el-popover
            placement="right"
            trigger="hover"
            content="提取次数：表示可以被多少个学员提取；每个学员提取一次即可获得课程，重复提取无效，数量不会减少"
          >
            <el-button
              slot="reference"
              icon="el-icon-question"
              type="text"
              style="position: relative; color: #606266; top: 1px"
            ></el-button>
          </el-popover>
        </span>
        <el-input
          v-model="ruleForm.stock"
          class="w618"
          placeholder="请输入可提取次数，上限9999；创建后编辑时只可增加不可减少数量"
          maxlength="4"
          @input="ruleForm.stock = ruleForm.stock.replace(/[^\d]/g, '')"
        >
          <template slot="append">上限9999</template>
        </el-input>
      </el-form-item>
      <el-form-item label="提取码有效期" prop="validity">
        <el-date-picker
          :picker-options="pickeroptions"
          value-format="yyyy-MM-dd"
          v-model="ruleForm.validity"
          style="width: 618px"
          type="date"
          placeholder="请选择提取码截止有效日期"
        ></el-date-picker>
        <p style="font-size: 12px; color: rgba(255, 53, 53, 1)">
          提示：系统默认当天23:59过期
        </p>
      </el-form-item>
    </el-form>
    <!-- 兑换课程 -->
    <div class="exchange">
      <div class="title">
        <span>提取课程（{{ ruleForm.courseInfo.length }}）</span>
        包含已上架和已下架的课程，可按需求选择一或多个课程，学生可通过提取码获得这些课程
      </div>
      <div class="add_button" @click="networkschoolSelectStatus = true">
        + 选择课程
      </div>
      <div class="classBox" v-if="ruleForm.courseInfo.length > 0">
        <div class="classInfo" v-for="(v, i) in ruleForm.courseInfo" :key="i">
          <img :src="v.photo || v.cover_img" alt="" />
          <ul>
            <li class="hidetext">{{ v.name }}</li>
            <li class="hidetext" v-if="v.course_type == 1">直播课</li>
            <li class="hidetext" v-else-if="v.course_type == 2">小班课</li>
            <li class="hidetext" v-else-if="v.course_type == 3">录播课</li>
            <li class="hidetext" v-else-if="v.course_type == 4">系列课</li>
            <li class="hidetext" v-else-if="v.graphic_id">图文</li>
          </ul>
          <div class="delete el-icon-close" @click="deleteInfo(i)"></div>
        </div>
      </div>
      <div class="noClass" v-else>
        <empty :isOld="false" :positionCenter="true" />
      </div>
      <div class="sub_btn">
        <el-button class="w120" type="primary" @click="submitForm('ruleForm')">
          保存
        </el-button>
      </div>
    </div>
    <!-- 选择商品 -->
    <networkschoolSelect
      @selectChangenetworkschool="closeGoodsConfirm"
      @singleselectcontentval="singleselectcontentval"
      title="添加商品"
      v-if="networkschoolSelectStatus"
      :dialogstatus.sync="networkschoolSelectStatus"
      :template="2"
      :ids="ids"
      :price_mold="1"
      :goodList="ruleForm.courseInfo"
      :goodlistType="false"
    ></networkschoolSelect>
    <!--End-->
  </div>
</template>
<script>
import empty from '@/components/Empty'
import tool from '@/assets/js/tool.js'
import networkschoolSelect from '@cm/base/networktypeSelect/networkschoolSelect'
export default {
  name: 'addExchangeActionList',
  components: { empty, networkschoolSelect },
  data() {
    return {
      // 选择课程弹窗
      networkschoolSelectStatus: false,
      // 日期选择框禁用当天之前日期
      pickeroptions: {
        disabledDate: time => {
          return time.getTime() < Date.now() - 1 * 24 * 60 * 60 * 1000
        },
      },
      // 表单数据
      ruleForm: {
        exchange_code: '',
        name: '',
        stock: '',
        validity: '',
        courseInfo: [],
      },
      rules: {
        name: [
          { required: true, message: '请输入兑换码名称', trigger: 'blur' },
        ],
        stock: [
          { required: true, message: '请输入兑换码数量', trigger: 'blur' },
        ],
        validity: [
          { required: true, message: '请选择日期', trigger: 'change' },
        ],
      },
    }
  },

  computed: {
    ids() {
      return this.ruleForm.courseInfo
        .filter(item => item.graphic_id)
        .map(item => item.graphic_id)
    },
  },

  mounted() {
    this.$root.$children[0].childPageOptions[0].name = '提取码'
    if (this.$route.query.exchange_id) {
      this.getDetail(this.$route.query.exchange_id)
      // 改变顶部标题
      this.$root.$children[0].childPageOptions[
        this.$root.$children[0].childPageOptions.length - 1
      ].name = '编辑提取码'
    } else {
      this.ruleForm.exchange_code = this.getRanNum()
      this.$root.$children[0].childPageOptions[
        this.$root.$children[0].childPageOptions.length - 1
      ].name = '新建提取码'
    }

    this.$parent.$parent.childPageOptions[0].prompt = ''
  },
  methods: {
    // 改变课程列表
    singleselectcontentval(list) {
      this.ruleForm.courseInfo.push(...list)
      this.networkschoolSelectStatus = false
    },
    // 关闭选择课程
    closeGoodsConfirm() {
      this.networkschoolSelectStatus = false
    },
    // 重置提取码
    resetTQM() {
      this.$confirm(
        '重置后，之前分享的提取码会失效，未提取的无法提取，已提取的不影响。需要重新分享重置后提取码才可提取！',
        '重置提取码',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      ).then(() => {
        this.ruleForm.exchange_code = this.getRanNum()
        this.$message({
          type: 'success',
          message: '重置成功!',
        })
      })
    },
    // 改变课程列表
    changeSelectList(list) {
      this.ruleForm.courseInfo = list
    },
    // 删除一项选择课程
    deleteInfo(index) {
      this.$confirm('是否取消该课程？', '取消课程', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.ruleForm.courseInfo.splice(index, 1)
        // this.ruleForm.courseInfo = this.ruleForm.courseInfo.filter(
        //   v => v.course_id != val.course_id
        // )
      })
    },
    // 提交确认
    submitForm(formName) {
      console.log('aaaaaa')
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.ruleForm.courseInfo.length == 0) {
            this.$message({
              message: '至少选择一门课程',
              type: 'warning',
            })
            return false
          }
          let formData = {},
            url = ''
          if (this.$route.query.exchange_id) {
            formData = {
              ...this.ruleForm,
              exchange_id: this.$route.query.exchange_id,
            }
            url = '/Exchange/editExchangeAction'
          } else {
            formData = {
              ...this.ruleForm,
            }
            url = '/Exchange/createExchangeAction'
          }
          this.$http({
            url,
            data: formData,
            callback: res => {
              if (res.code == 200) {
                // this.$message({
                //   type: 'success',
                //   message: res.info
                // })
                this.$router.go(-1)
              }
            },
          })
        }
      })
    },
    // 如果是编辑，获取数据详情
    getDetail(exchange_id) {
      this.$http({
        url: '/Exchange/editExchangeAction?exchange_id=' + exchange_id,
        callback: res => {
          if (res.code == 200) {
            this.ruleForm = res.data
            this.ruleForm.validity = tool.formatTimeStamp(
              this.ruleForm.validity,
              'yyyy-MM-dd'
            )
          }
        },
      })
    },
    getRanNum() {
      var result = []
      for (var i = 0; i < 4; i++) {
        var ranNum = Math.ceil(Math.random() * 25) //生成一个0到25的数字
        //大写字母'A'的ASCII是65,A~Z的ASCII码就是65 + 0~25;然后调用String.fromCharCode()传入ASCII值返回相应的字符并push进数组里
        result.push(String.fromCharCode(65 + ranNum))
      }
      return result.join('').toLowerCase()
    },
  },
}
</script>
<style lang="less" scoped>
#addExchangeActionList {
  background: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  .tip {
    width: 730px;
    height: 40px;
    background: rgba(255, 242, 242, 1);
    border-radius: 2px;
    border: 1px solid rgba(255, 212, 212, 1);
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 40px;
    text-indent: 5px;
    color: rgba(255, 53, 53, 1);
  }
  .el-form {
    /deep/ .el-form-item {
      .el-form-item__label {
        span {
          span {
          }
        }
      }
    }
  }
  .el-dialog {
    min-width: 955px;
    overflow: hidden;
    .el-dialog__header {
      padding: 0px;
      height: 48px;
      color: rgba(51, 51, 51, 1);
      background: rgba(245, 245, 245, 1);
    }
    .el-dialog__body {
      padding: 0px;
    }
    .zi1 {
      float: left;
      margin-top: 15px;
      margin-left: 2%;
    }
    .zi2 {
      float: left;
      margin-left: 40%;
      margin-top: 15px;
    }
    .center {
      // background-color: red;
      height: 500px;
      width: 100%;
      .left {
        box-sizing: border-box;
        float: left;
        // background-color: yellow;
        width: calc(50% - 1px);
        height: 100%;
        .el-row {
          // background-color: red;
          padding: 20px;
          height: 16%;
        }
        .bottom {
          padding: 0px 20px;
          height: 85%;
          ul {
            height: 100%;
            overflow-y: auto;
            li {
              height: 38px;
              margin-bottom: 20px;
              .tu1 {
                width: 68px;
                height: 38px;
                background: rgba(110, 110, 110, 1);
                float: left;
                img {
                  width: 68px;
                  height: 38px;
                }
              }
              .zi {
                float: left;
                margin-left: 10px;
                .zi3 {
                  width: 230px;
                  font-size: 12px;
                  color: rgba(51, 51, 51, 1);
                }
                .zi4 {
                  color: rgba(153, 153, 153, 1);
                  font-size: 12px;
                  margin-top: 12px;
                }
              }
              .buttonn {
                float: right;
              }
            }
          }
        }
      }
      .right {
        border-left: 1px solid rgba(236, 236, 236, 1);
        float: right;
        // background-color: green;
        width: 50%;
        height: 100%;
        .top1 {
          padding: 20px 20px 0px 20px;
          height: 87%;
          background-color: #fff;
          overflow-y: auto;
          ul {
            li {
              height: 38px;
              margin-bottom: 20px;
              .tu1 {
                width: 68px;
                height: 38px;
                background: rgba(110, 110, 110, 1);
                float: left;
              }
              .zi {
                float: left;
                margin-left: 10px;
                .zi3 {
                  width: 230px;
                  font-size: 12px;
                  color: rgba(51, 51, 51, 1);
                }
                .zi4 {
                  color: rgba(153, 153, 153, 1);
                  font-size: 12px;
                  margin-top: 12px;
                }
              }
              .buttonn {
                float: right;
              }
            }
          }
          li:last-child {
            margin-bottom: 0px;
          }
        }
        .bottom1 {
          height: 13%;
          border-top: 1px solid rgba(236, 236, 236, 1);
          .el-button {
            margin-top: 10px;
            float: right;
            margin-right: 20px;
          }
        }
      }
    }
  }
  .exchange {
    .title {
      width: 100%;
      height: 40px;
      border-bottom: 1px solid rgba(226, 226, 226, 1);
      line-height: 40px;
      color: rgba(102, 102, 102, 1);
      font-size: 12px;
      span {
        font-size: 16px;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
      }
    }
    .add_button {
      width: 120px;
      height: 42px;
      line-height: 42px;
      border-radius: 4px;
      border: 1px solid rgba(10, 162, 155, 1);
      cursor: pointer;
      color: rgba(10, 162, 155, 1);
      font-size: 14px;
      text-align: center;
      margin-top: 35px;
      margin-bottom: 25px;
    }
    .classBox {
      width: 100%;
      min-height: 60px;
      padding: 10px 0;
      margin-bottom: 25px;
      .classInfo {
        width: 48%;
        height: 60px;
        display: inline-block;
        margin-right: 1%;
        margin-bottom: 10px;
        transition: all 0.5s;
        img {
          float: left;
          width: 88px;
          height: 60px;
          background: blue;
        }
        ul {
          float: left;
          width: calc(100% - 120px - 14px);
          height: 60px;
          li {
            width: 100%;
            height: 30px;
            line-height: 30px;
            text-indent: 12px;
            &:nth-of-type(1) {
              font-size: 14px;
              color: rgba(51, 51, 51, 1);
            }
            &:nth-of-type(2) {
              font-size: 12px;
              color: rgba(153, 153, 153, 1);
            }
          }
        }
        .delete {
          float: left;
          width: 14px;
          height: 14px;
          margin-top: 20px;
          cursor: pointer;
          display: none;
        }
        &:hover {
          background: rgba(153, 153, 153, 0.2);
          .delete {
            display: block;
          }
        }
      }
    }
    .sub_btn {
      width: 100%;
      // display: flex;
      // justify-content: center;
    }
  }
  .noClass {
    width: 100%;
    height: 300px;
  }
}
</style>
